import Contact from './Contact'
import Footer from './Footer'
import Header from './Header'
import Menu from './Menu'
import Social from './Social'
import Layout from './Layout'

export {
    Contact,
    Footer,
    Header,
    Menu,
    Social,
    Layout,
};

export default {
    Contact,
    Footer,
    Header,
    Menu,
    Social,
    Layout,
};
