import React from 'react';
import _ from 'lodash';
import { navigateTo } from "gatsby-link";


function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}
export default class Contact extends React.Component {
    state = {}

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
            .then(() => navigateTo(form.getAttribute("action")))
            .catch(error => alert(error));
    };
    render() {
        return (
            <section>
                <h2>{_.get(this.props, 'pageContext.site.siteMetadata.contact.title')}</h2>
                <form
                    name="contact"
                    method="post"
                    action="/aitäh"
                    netlify
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                >
                    <div className="fields">
                        <div className="field half">
                            <input type="text" name="name" id="Nimi" placeholder="Nimi" />
                        </div>
                        <div className="field half">
                            <input onChange={this.handleChange} type="email" name="email" id="email" placeholder="Email" />
                        </div>
                        <div className="field">
                            <textarea onChange={this.handleChange} name="message" id="message" placeholder="Sõnum" />
                        </div>
                    </div>
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                        <label>
                            Don’t fill this out:{" "}
                            <input name="bot-field" onChange={this.handleChange} />
                        </label>
                    </p>
                    <ul className="actions">
                        <li>
                            <input
                                type="submit"
                                value="Saada"
                                className="primary"
                            />
                        </li>
                    </ul>
                </form>
            </section>
        );
    }
}
